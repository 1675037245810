<template>
  <div>
    <div style="margin: 20px 0">
      <el-radio-group v-model="radioDialog" border="none">
        <el-radio-button label="all">全平台</el-radio-button>
        <el-radio-button
          v-for="item in sites"
          :label="item.value"
          :key="item.label"
        >
          {{ item.label }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="tableList">
      <fieldset class="left">
        <legend style="margin-left: 10px">已有KOL</legend>
        <el-table
          :data="leftTableData[radioDialog]"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          height="250"
        >
          <el-table-column prop="id" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column label="平台" width="100" align="center">
            <template #default="{ row }">
              {{ platformToZn(row.platform) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="KOL名称" align="center">
          </el-table-column>
        </el-table>
      </fieldset>
      <fieldset class="right">
        <legend style="margin-left: 10px">未入库KOL</legend>
        <el-table
          :data="rightTableData[radioDialog]"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          height="250"
        >
          <el-table-column prop="id" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column label="平台" width="100" align="center">
            <template #default="{ row }">
              {{ platformToZn(row.platform) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="KOL名称"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="kolId" label="KOL ID" align="center">
          </el-table-column>
          <el-table-column prop="url" label="KOL链接" align="center">
          </el-table-column>
        </el-table>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "submitCom",
  data() {
    return {
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      radioDialog: "all",
    };
  },
  props: {
    leftTableData: {
      type: Object,
      default() {
        return {
          all: [],
          wechat: [],
          weibo: [],
          douyin: [],
          xiaohongshu: [],
          bilibili: [],
          kuaishou: [],
        };
      },
    },
    rightTableData: {
      type: Object,
      default() {
        return {
          all: [],
          wechat: [],
          weibo: [],
          douyin: [],
          xiaohongshu: [],
          bilibili: [],
          kuaishou: [],
        };
      },
    },
  },
  computed: {
    ...mapGetters(["sites"]),
  }
};
</script>

<style scoped lang="scss">
.tableList {
  display: flex;

  .left {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    margin-right: 30px;
  }

  .right {
    flex: 2;
    padding: 20px;
    border: 1px solid #ccc;
  }
}
</style>
