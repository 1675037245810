<template>
  <div class="addKolWrapper">
    <el-input
      placeholder="名称或关键字，空格分隔"
      v-model="searchWord"
      style="width: 500px"
      clearable
      @keyup.enter="handleSearch"
      @clear="handleSearch"
    >
      <template #prepend>
        <el-select
          style="width: 108px"
          v-model="stockKolPlatform"
          @change="handleSearch"
          placeholder="请选择平台"
        >
          <el-option
            v-for="item in sites"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </template>
      <template #append>
        <el-button @click="handleSearch" icon="el-icon-search"></el-button>
      </template>
    </el-input>
    <div style="margin: 15px 0">
      <main-title title="在库KOL">
        <template #left>
          <el-radio-group
            v-model="stockKolPlatform"
            size="small"
            @change="handleSearch"
          >
            <el-radio-button
              v-for="item in sites"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </template>
      </main-title>
      <transfer-com
        style="height: 380px; margin-top: 20px"
        :stock-kol-platform="stockKolPlatform"
        :transfer-data="transferData"
        @updateData="updateLeftData"
      />
    </div>
    <div>
      <main-title title="待追加KOL" style="margin-bottom: 20px">
        <template #left>
          <el-radio-group v-model="noKolPlatform" border="none" size="small">
            <el-radio-button label="all">全平台</el-radio-button>
            <el-radio-button
              v-for="item in sites"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </template>
        <template #right>
          <a
            class="downloadBtn"
            target="_blank"
            href="https://omnivoice-kol.oss-cn-hangzhou.aliyuncs.com/omnivoice/template-files/omniVoice_manualForAddKol_20220328.pdf"
          >
            点击下载KOL规范文档
          </a>
        </template>
      </main-title>
      <wait-add-kol
        :search-add-kol="searchAddKol"
        :no-kol-platform="noKolPlatform"
        @updateData="updateRightData"
      />
    </div>
    <div class="submitFooter">
      <el-button type="primary" @click="showSubmit" size="mini">提交</el-button>
      <el-button size="mini" @click="cancelAddKol">取消</el-button>
    </div>
    <!-- 提交时显示-->
    <el-dialog v-model="showTabledialogVisible" :width="900">
      <submit-com
        :left-table-data="leftTableData"
        :right-table-data="transferNonExtend"
      />
      <template #footer>
        <div>
          <el-button @click="showTabledialogVisible = false">取 消</el-button>
          <el-button type="primary" :disabled="isDisable" @click="commitAddKol"
            >确 定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchHaveKol,
  submitKol,
  getAmount,
  downloadKolFile,
} from "@/api/collection";
import { mapGetters } from "vuex";
import mainTitle from "@/components/common/mainTitle";
import transferCom from "@/components/addKol/transferCom";
import waitAddKol from "@/components/addKol/waitAddKol";
import submitCom from "@/components/addKol/submitCom";

export default {
  data() {
    return {
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      searchWord: "",
      stockKolPlatform: "wechat",

      // 穿梭框总数据
      transferData: {
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      // 待追加kol数据
      transferNonExtend: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },

      noKolPlatform: "all",

      showTabledialogVisible: false,
      // 提交时，显示的已有kol
      leftTableData: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },

      isDisable: false,
      searchAddKol: [],
    };
  },
  computed: {
    ...mapGetters(["sites"]),
    params() {
      let params = {
        platform: this.stockKolPlatform,
        kolNames: this.searchWord.trim().replace(/\s+/g, " "),
      };
      return params;
    },
  },
  props: {
    collectionId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    async handleSearch() {
      let { code, data } = await searchHaveKol(this.params);
      if (code === 0) {
        if (data.nonExistedKols.length !== 0) {
          let names = data.nonExistedKols.map((_) => _.name);
          this.$notify({
            type: "success",
            title: "消息",
            message: `${names.join(
              ","
            )}不在库，已添加至下方待追加清单中，请补完KOL相关信息`,
            duration: 10000,
          });
        }
        this.formatSourceData(data);
      }
    },
    formatSourceData({ existedKols, nonExistedKols }) {
      // existedKols: [], nonExistedKols:[]
      // existedKols：在库kol所有数据，不区分平台
      // nonExistedKols：不在库数据，直接添加到待追加kol列表中
      // 如果右侧数据中有的，则不显示在左侧数据中
      // transferData：穿梭框总数据
      this.transferData[this.stockKolPlatform] = [...existedKols];
      this.searchAddKol = this.formatNoExistedData(nonExistedKols);
    },
    formatNoExistedData(data) {
      // id的加工放在哪里
      return data.map((v) => ({
        name: v.name,
        url: "",
        kolId: "",
        // 是否正在编辑
        isEdit: true,
        // 是否是搜索出来的kol
        searchKol: true,
        original: {
          name: v.name,
          url: "",
          kolId: "",
          platForm: "",
        },
        platform: this.stockKolPlatform,
      }));
    },
    // 提交
    showSubmit() {
      //提交前校验
      if (
        !this.leftTableData.all.length &&
        !this.transferNonExtend.all.length
      ) {
        this.$message({
          message: "请添加KOL",
          type: "info",
        });
        return;
      }
      const allEdit = this.transferNonExtend.all.every((item) => {
        return !item.isEdit;
      });
      if (!allEdit) {
        this.$message({
          message: "请完成所有kol编辑",
          type: "info",
        });
        return;
      }
      this.showTabledialogVisible = true;
    },
    async commitAddKol() {
      this.isDisable = true;
      let kols = this.leftTableData.all.map((v) => v.kolId);
      let nonKols = [...this.transferNonExtend.all];
      let addKolParams = {
        collectionId: this.collectionId,
        kols,
        nonKols,
      };
      // 校验剩余额度
      const bool = await this.judgeLimit(kols.length + nonKols.length);
      if (!bool) return;
      let { code } = await submitKol(addKolParams);
      if (code === 0) {
        this.$message({
          message: "提交成功!",
          type: "success",
        });
        this.showTabledialogVisible = false;
        this.$router.go(-1);
      } else if (code === 3001) {
        this.$message({
          message: "单个收藏夹最多添加1000个KOL",
          type: "error",
          duration: 5000,
        });
      } else {
        this.$message.error("提交失败！");
      }
      setTimeout(() => {
        this.isDisable = false;
      }, 1500);
    },
    cancelAddKol() {
      this.showTabledialogVisible = false;
      this.$router.go(-1);
    },
    async judgeLimit(len) {
      let {
        code,
        data: { total, collected },
      } = await getAmount();
      if (code === 0) {
        if (total !== "inf") {
          let totalCollected = len + collected * 1;
          if (totalCollected > total) {
            this.$message({
              message: `提交失败，您的常用达人KOL额度为${total}, 添加后为${totalCollected}, 超出额度,请联系管理员增加额度`,
              type: "info",
            });
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    updateLeftData(val) {
      this.leftTableData = Object.assign({ all: [] }, val);
      this.leftTableData.all = [
        ...this.leftTableData.wechat,
        ...this.leftTableData.weibo,
        ...this.leftTableData.douyin,
        ...this.leftTableData.xiaohongshu,
        ...this.leftTableData.bilibili,
        ...this.leftTableData.kuaishou,
      ];
      this.leftTableData.all.forEach((item, i) => (item.id = ++i));
    },
    updateRightData(val) {
      this.transferNonExtend = val;
    },
    downloadKolFile() {
      downloadKolFile();
    },
  },
  components: {
    mainTitle,
    transferCom,
    waitAddKol,
    submitCom,
  },
};
</script>

<style lang="scss">
.addKolWrapper {
  .el-table__row .cell {
    height: 40px;
    line-height: 38px !important;
  }

  .el-transfer-panel {
    width: 400px;
    height: 100%;
  }

  .el-transfer-panel__body {
    height: 380px;
  }

  .el-transfer-panel .el-transfer-panel__footer {
    height: 80px;
    background-color: #f5f7fa;
  }

  .platformInput.el-select .el-input--suffix .el-input__inner {
    width: 110px;
  }

  .el-select .el-input--suffix .el-input__inner {
    width: 108px;
  }

  .search .el-input__inner {
    width: 280px;
  }
}
</style>

<style scoped lang="scss">
.addKolWrapper {
  background-color: #fff;
  padding: 10px 5px;
  height: calc(100% - 40px);
  overflow: auto;

  .downloadBtn {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    margin-right: 25px;

    &:hover {
      color: #409eff;
      border-color: #c6e2ff;
      background-color: #ecf5ff;
    }
  }

  .cellInput {
    padding: 0 10px;
    line-height: 32px;
  }

  .submitFooter {
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 30px;
    height: 40px;
    line-height: 40px;
    background-color: #ccc;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 160px;
    z-index: 1;
  }
}
</style>
