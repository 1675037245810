<template>
  <el-transfer
    :titles="['已有KOL', '待收藏KOL']"
    v-model="kolValue"
    :props="{
      key: 'kolId',
      label: 'name',
    }"
    :data="transferInfo"
    @change="handleData"
  >
    <template #default="{ option }">
      <p @mouseenter="hoverHandle(option.kolId)" @mouseleave="showInfo = {}">
        {{ option.name }}
      </p>
    </template>
    <template #left-footer>
      <div v-loading="getInfoLoading" v-show="infoPosition === 'left'">
        <div class="noDesc" v-if="JSON.stringify(showInfo) === '{}'">
          无简介
        </div>
        <div v-else class="kolInfo">
          <div class="left">
            <img :src="showInfo.logoUrl" alt="" />
          </div>
          <div class="center">
            <p v-if="stockKolPlatform === 'douyin'">
              抖音号: {{ showInfo.kolNo }}
            </p>
            <p v-if="stockKolPlatform === 'wechat'">
              微信号: {{ showInfo.kolNo }}
            </p>
            <p class="desc">{{ showInfo.detail }}</p>
          </div>
          <div class="right">
            <qr-code :value="showInfo.qrCode" :options="{ width: 60 }" />
          </div>
        </div>
      </div>
    </template>
    <template #right-footer>
      <div v-loading="getInfoLoading" v-show="infoPosition === 'right'">
        <div class="noDesc" v-if="JSON.stringify(showInfo) === '{}'">
          无简介
        </div>
        <div v-else class="kolInfo">
          <div class="left">
            <img :src="showInfo.logoUrl" alt="" />
          </div>
          <div class="center">
            <p v-if="stockKolPlatform === 'douyin'">
              抖音号: {{ showInfo.kolNo }}
            </p>
            <p v-if="stockKolPlatform === 'wechat'">
              微信号: {{ showInfo.kolNo }}
            </p>
            <div class="desc">{{ showInfo.detail }}</div>
          </div>
          <div class="right">
            <qr-code :value="showInfo.qrCode" :options="{ width: 60 }" />
          </div>
        </div>
      </div>
    </template>
  </el-transfer>
</template>

<script>
import { getPopoverData } from "@/api/rankingList";

export default {
  name: "transferCom",
  data() {
    return {
      kolValue: [],
      getInfoLoading: false,
      transferInfo: [],
      showInfo: {},
      waitColData: {
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
    };
  },
  computed: {
    infoPosition() {
      return this.kolValue.includes(this.showInfo.kolId) ? "right" : "left";
    },
  },
  props: {
    stockKolPlatform: {
      type: String,
      default: "wechat",
    },
    transferData: {
      type: Object,
      default() {
        return {
          wechat: [],
          weibo: [],
          douyin: [],
          xiaohongshu: [],
          bilibili: [],
          kuaishou: [],
        };
      },
    },
  },
  methods: {
    async hoverHandle(mKolid) {
      this.getInfoLoading = true;
      let res = await getPopoverData({
        mKolid,
        platform: this.stockKolPlatform,
      });
      this.showInfo = res.data;
      this.getInfoLoading = false;
    },
    handleData(value, direction, movedKeys) {
      if (direction === "left") {
        movedKeys.forEach((kolId) => {
          const i = this.waitColData[this.stockKolPlatform].findIndex(
            (kol) => kol.kolId === kolId
          );
          this.waitColData[this.stockKolPlatform].splice(i, 1);
        });
      }
      if (direction === "right") {
        movedKeys.forEach((kolId) => {
          const kol = this.transferData[this.stockKolPlatform].find(
            (kol) => kol.kolId === kolId
          );
          kol.platform = this.stockKolPlatform;
          this.waitColData[this.stockKolPlatform].push(kol);
        });
      }
    },
  },
  watch: {
    transferData: {
      handler(newVal) {
        // 重新搜索时也将之前搜索的显示
        let res = new Map();
        this.transferInfo = [
          ...newVal[this.stockKolPlatform],
          ...this.waitColData[this.stockKolPlatform],
        ].filter((a) => !res.has(a.kolId) && res.set(a.kolId, 1));
      },
      deep: true,
    },
    waitColData: {
      handler(newVal) {
        this.$emit("updateData", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.noDesc {
  height: 80px;
  text-align: center;
  padding-top: 30px;
  color: #909399;
}

.kolInfo {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 10px;

  .left {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    width: 60px;
    height: 60px;
  }

  .center {
    height: 100%;
    flex: 1;
    margin: 0 10px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .desc {
      max-height: 40px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
