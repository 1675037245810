<template>
  <el-table
    :data="tableInfo"
    :header-cell-style="headerStyle"
    :cell-style="cellStyle"
    border
  >
    <template #empty>
      <el-button type="primary" @click="addNewLine">添加数据</el-button>
    </template>
    <el-table-column align="center" label="" width="50">
      <template #default="{ row }">
        <el-tooltip effect="dark" content="添加" placement="top">
          <i
            v-if="
              tableInfo.length && row.id === tableInfo[tableInfo.length - 1].id
            "
            class="el-icon-plus"
            style="
              font-size: 16px;
              font-weight: 600;
              vertical-align: middle;
              color: #51a6f0;
              cursor: pointer;
            "
            @click="addNewLine"
          ></i>
          <i v-else></i>
        </el-tooltip>
      </template>
    </el-table-column>

    <el-table-column align="center" label="序号" width="60">
      <template #default="{ row }">
        {{ row.id }}
      </template>
    </el-table-column>

    <el-table-column align="center" label="名称">
      <template #default="{ row }">
        <!-- 设置自动聚焦 -->
        <div v-if="row.isEdit" class="cellInput">
          <el-input v-model="row.name" placeholder="请输入kol名称"></el-input>
        </div>
        <span v-else>{{ row.name }}</span>
      </template>
    </el-table-column>

    <el-table-column prop="platForm" label="平台" width="150" align="center">
      <template #default="{ row }">
        <div v-if="row.isEdit && !row.searchKol && noKolPlatform === 'all'">
          <el-select
            v-model="newKolPlatform"
            placeholder="请选择"
            @change="changePlatform"
          >
            <el-option
              v-for="item in sites"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <span v-else>{{ platformToZn(row.platform) }}</span>
      </template>
    </el-table-column>

    <el-table-column align="center" label="KOL ID">
      <template #header>
        <el-tooltip effect="dark" placement="top">
          <template #content>
            <div v-html="idFormat"></div>
          </template>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <span>KOL ID</span>
            <img
              style="
                width: 20px;
                height: 20px;
                margin-left: 6px;
                margin-bottom: 2px;
              "
              :src="require(`./helpIcon.svg`)"
              alt=""
            />
          </div>
        </el-tooltip>
      </template>
      <template #default="{ row }">
        <div v-if="row.isEdit" class="cellInput">
          <el-input
            @blur="bizBlur(row)"
            v-model="row.kolId"
            placeholder="请输入kol id"
          >
          </el-input>
        </div>
        <span v-else>{{ row.kolId }}</span>
      </template>
    </el-table-column>

    <el-table-column align="center" label="链接">
      <template #default="{ row }">
        <div v-if="row.isEdit" class="cellInput">
          <el-input
            @keyup.enter="resolverUrl(row)"
            @blur="resolverUrl(row)"
            v-model="row.url"
            placeholder="请输入kol链接"
          >
          </el-input>
        </div>
        <span v-else>{{ row.url }}</span>
      </template>
    </el-table-column>

    <el-table-column align="center" label="操作">
      <template #default="{ row }">
        <el-tooltip
          effect="dark"
          :content="row.isEdit ? '确定' : '编辑'"
          placement="top"
        >
          <svg-icon
            class="wait_add_icon"
            style="margin-right: 10px"
            @click="isSureHandle(row)"
            :class-name="row.isEdit ? 'icon18queding' : 'iconbianji'"
          />
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :content="row.isEdit ? '取消' : '删除'"
          placement="top"
        >
          <svg-icon
            class="wait_add_icon"
            v-if="row.isEdit"
            @click="isEditHandle(row)"
            class-name="iconshanchu"
          />
          <i
            v-else
            @click="delAddKol(row)"
            class="el-icon-delete"
            style="font-size: 18px; cursor: pointer"
          ></i>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "waitAddKol",
  data() {
    return {
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      transferNonExtend: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      newKolPlatform: "",
      idFormat: `切换未入库KOL平台，查看 KOL ID 获取方式`,
      bizFlag: false,
      tempPlatform: "",
    };
  },
  props: {
    noKolPlatform: {
      type: String,
      default: "all",
    },
    searchAddKol: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(["sites"]),
    tableInfo() {
      return this.transferNonExtend[this.noKolPlatform];
    },
  },
  methods: {
    addNewLine() {
      this.newKolPlatform =
        this.noKolPlatform === "all" ? "" : this.noKolPlatform;
      let bool = this.transferNonExtend.all.some((v) => v.isEdit === true);
      if (bool) {
        this.$message({
          message: "请完成当前KOL编辑",
          type: "info",
        });
        return;
      }
      // 对应平台待添加数量为0
      if (this.tableInfo.length === 0) {
        const id = this.transferNonExtend.all.length + 1;
        this.transferNonExtend.all.push({
          id,
          name: "",
          kolId: "",
          url: "",
          isEdit: true,
          original: { name: "", kolId: "", url: "", platForm: "" },
          platform: this.noKolPlatform,
          newKol: true,
          searchKol: false,
        });
      } else {
        const newId = this.tableInfo[this.tableInfo.length - 1].id + 1;
        const item = {
          id: newId,
          name: "",
          kolId: "",
          url: "",
          isEdit: true,
          original: { name: "", kolId: "", url: "", platForm: "" },
          platform: this.noKolPlatform,
          newKol: true,
          searchKol: false,
        };
        this.transferNonExtend[this.noKolPlatform].push(item);
        if (this.noKolPlatform !== "all") {
          this.transferNonExtend.all.push(item);
        }
      }
    },
    formatOtherPlatformData() {
      this.transferNonExtend.wechat = this.transferNonExtend.all.filter(
        (v) => v.platform === "wechat"
      );
      this.transferNonExtend.weibo = this.transferNonExtend.all.filter(
        (v) => v.platform === "weibo"
      );
      this.transferNonExtend.douyin = this.transferNonExtend.all.filter(
        (v) => v.platform === "douyin"
      );
      this.transferNonExtend.xiaohongshu = this.transferNonExtend.all.filter(
        (v) => v.platform === "xiaohongshu"
      );
      this.transferNonExtend.bilibili = this.transferNonExtend.all.filter(
        (v) => v.platform === "bilibili"
      );
      this.transferNonExtend.kuaishou = this.transferNonExtend.all.filter(
        (v) => v.platform === "kuaishou"
      );
    },
    // 只有all全平台下新添加的kol才可以改变平台
    changePlatform() {
      this.tableInfo.forEach((v) => {
        if (v.isEdit) {
          v.platform = this.newKolPlatform;
        }
      });
    },
    bizBlur(scope) {
      if (scope.platform === "wechat") {
        let reg = new RegExp(
          "^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$"
        );
        if (!reg.test(scope.kolId) && scope.kolId !== "") {
          this.bizFlag = true;
        } else {
          this.bizFlag = false;
        }
      } else {
        this.bizFlag = false;
      }
    },
    resolverUrl(row) {
      this.tempPlatform = this.urlToPlatform(row.url);
      if (this.tempPlatform !== null && this.noKolPlatform === "all") {
        this.newKolPlatform = this.tempPlatform;
        row.platform = this.tempPlatform;
      }
    },
    // 确认或编辑
    isSureHandle(row) {
      const { name, kolId, url, platform, isEdit } = row;
      // 通用错误判断
      if (platform === "all") {
        this.$message({
          message: "平台不能为空",
          type: "warning",
        });
        return false;
      }
      // 平台为微信,判断biz是否填写正确
      if (platform === "wechat") {
        if (this.bizFlag) {
          this.$message({
            message: "biz 格式不正确，请重新填写",
            type: "error",
          });
          return false;
        }
      }

      this.tempPlatform = this.urlToPlatform(url);
      if (this.tempPlatform === null && url !== "") {
        this.$message.error("链接填写不合法!");
        return false;
      }
      if (url !== "" && this.tempPlatform !== platform) {
        this.$message({
          message: `此链接为'${this.platformToZn(
            this.tempPlatform
          )}'平台链接，请重新填写!`,
          type: "warning",
        });
        return false;
      }
      if (url === "" && kolId === "") {
        this.$message({
          message: "链接或者KOL ID 必须填写一个",
          type: "warning",
        });
        return false;
      }
      // 要编辑需确保别的都已完成
      if (!isEdit) {
        let bool = this.transferNonExtend.all.some((v) => v.isEdit === true);
        if (bool) {
          this.$message({
            message: "请完成当前KOL编辑",
            type: "info",
          });
          return false;
        }
      }
      // 确认
      row.original = {
        kolId,
        name,
        platform,
        url,
      };
      row.isEdit = !row.isEdit;
      delete row.newKol;
    },
    // 取消编辑
    isEditHandle(row) {
      if (row.newKol) {
        // 删除
        this.delAddKol(row);
      } else {
        // 取消：还原数据
        row.name = row.original.name;
        row.kolId = row.original.kolId;
        row.platform = row.original.platform;
        row.url = row.original.url;
        row.isEdit = false;
      }
    },
    delAddKol(row) {
      const i = this.transferNonExtend.all.findIndex((_) => _.id === row.id);
      this.transferNonExtend.all.splice(i, 1);
    },
    searchAddKolId(kols) {
      this.newKolPlatform = kols[0].platform;
      this.transferNonExtend.all = this.transferNonExtend.all.filter(
        (item) => !item.isEdit
      );
      kols.forEach((item, i) => {
        item.id = this.transferNonExtend.all.length
          ? this.transferNonExtend.all.length + i + 1
          : ++i;
        item.newKol = true;
      });
      this.transferNonExtend.all = [...this.transferNonExtend.all, ...kols];
    },
  },
  watch: {
    searchAddKol(newVal) {
      newVal.length ? this.searchAddKolId(newVal) : "";
    },
    "transferNonExtend.all": {
      handler(newVal) {
        this.formatOtherPlatformData();
        this.$emit("updateData", this.transferNonExtend);
      },
      deep: true,
    },
    noKolPlatform(newValue) {
      this.transferNonExtend[newValue].forEach((v, i) => {
        v.id = ++i;
      });
      switch (newValue) {
        case "all":
          this.idFormat = `切换待追加KOL平台，查看 KOL ID 获取方式`;
          break;
        case "wechat":
          this.idFormat = `1) 在浏览器中打开文章链接<br/>
                           2) 右键，点击“显示网页源代码”<br/>
                           3) 搜索"biz ="，即可获得biz（var biz = "MTQzMjE1NjQwMQ=="）
                          `;
          break;
        case "weibo":
          this.idFormat = `1) 点进用户主页<br/>
                           2) 点击主页的右上角找到“…”选项，并点击“复制链接(https://weibo.com/u/5888671022)<br/>
                           3) 从链接中获取id，参考格式 5888671022
                          `;
          break;
        case "douyin":
          this.idFormat = `1) 在用户界面搜索需要检测的KOL，并点击相应KOL进入主页<br/>
                           2) 双击抖音号即可复制 id (166902759)
                          `;
          break;
        case "xiaohongshu":
          this.idFormat = `方式1：<br/>
                           1) 进入用户主页<br/>
                           2) 点击“…”选项后，选择“复制链接”(https://www.xiaohongshu.com/.../5b4c369611be104d815b1ba9)<br/>
                           3) 从链接中获取id，参考格式 5b4c369611be104d815b1ba9<br/>
                           方式2：<br/>
                           1) 在用户界面搜索需要检测的KOL，并点击相应KOL进入主页<br/>
                           2) 双击小红书号即可复制
                          `;
          break;
        case "bilibili":
          this.idFormat = `1) 进入用户主页<br/>
                           2) 点击“…”选项后，选择“复制链接”(https://space.bilibili.com/394609671/)<br/>
                           3) 从链接中获取，参考格式 394609671
                          `;
          break;
        case "kuaishou":
          this.idFormat = "快手只支持链接，暂不支持id提交";
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cellInput {
  padding: 0 10px;
  line-height: 32px;
}

.wait_add_icon {
  cursor: pointer;
  font-size: 20px;
}
</style>
